<template>
  <section class="products-main">
    <b-container>
      <div class="py-5 products-main-card-container">
        <div class="products-main-container-title">
          <span class="mt-auto">A</span>
          <span>L</span>
          <span>F</span>
          <span>A</span>
          <span>I</span>
          <span>S</span>
          <span>A</span>
          <span>L</span>
        </div>
        <div class="flex-grow-1 mx-50 products-main-container">
          <h4 class="products-title">
            {{ t("anexplanationabouttheproducts") }}
          </h4>
          <div class="pb-3" v-html="productsInfo.description"></div>
          <b-row>
            <b-col
              cols="12"
              lg="6"
              v-for="(stage, index) in productsInfo.stages"
              :key="index"
            >
              <div class="sub-card-items">
                <img
                  :src="
                    stage.imageDto.path.replace(
                      '~',
                      $store.getters['app/domainHost']
                    )
                  "
                />
              </div>
            </b-col>
          </b-row>
          <h2 class="text-center pt-1">
            <strong>{{ productsInfo.title }}</strong>
          </h2>
        </div>
        <div class="products-main-container-title">
          <span>S</span>
          <span>P</span>
          <span>I</span>
          <span>N</span>
          <span>N</span>
          <span>I</span>
          <span>N</span>
          <span>G</span>
          <span class="title-border"></span>
        </div>
      </div>
      <b-row>
        <b-col cols="12" md="6" lg="3">
          <b-input-group>
            <template #append>
              <b-input-group-text class="bg-primary">
                <unicon name="search" width="18" height="18" fill="#fff" />
              </b-input-group-text>
            </template>
            <b-form-input
              v-model="filterDto.search"
              @input="filterProductAction()"
              class="bg-primary text-white border-right-0"
              :placeholder="t('website').products.search"
            >
            </b-form-input>
          </b-input-group>
        </b-col>
        <b-col cols="12" md="6" lg="3">
          <v-select
            v-model="filterDto.sup"
            @input="filterProductAction()"
            class="bg-primary text-white"
            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
            :placeholder="t('website').products.subCategory"
            :clearable="false"
            label="name"
            :options="websiteSubCategoriesList"
            :reduce="(option) => option.id"
          />
        </b-col>
        <b-col cols="12" md="6" lg="3">
          <v-select
            v-model="filterDto.main"
            @input="filterProductAction()"
            class="bg-primary text-white"
            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
            :placeholder="t('website').products.mainCategory"
            :clearable="false"
            label="name"
            :options="websiteMainCategoriesList"
            :reduce="(option) => option.id"
          />
        </b-col>
        <b-col cols="12" md="6" lg="3">
          <v-select
            class="bg-primary text-white"
            @input="sortProducts()"
            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
            :placeholder="t('website').products.filter"
            :clearable="false"
            v-model="sortType"
            label="title"
            :options="[
              { title: 'ابجدية تصاعدي', value: 0 },
              { title: 'ابجدية تنازلي', value: 1 },
              { title: 'السعر تصاعدي', value: 2 },
              { title: 'السعر تنازلي', value: 3 },
            ]"
            :reduce="(option) => option.value"
          />
        </b-col>
      </b-row>
      <div class="products-cards-container py-5">
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
            v-for="(product, i) in activeProductWeb"
            :key="i"
            class="products-card-parent"
          >
            <div
              class="products-card"
              data-aos="fade-up"
              data-aos-duration="5000"
            >
              <router-link :to="'/products/' + product.id">
                <h4 class="products-card-title">{{ product.name }}</h4>
                <div class="products-card-image-desc-container">
                  <img
                    class="products-card-image"
                    :src="
                      product.imagePath.replace(
                        '~',
                        $store.getters['app/domainHost']
                      )
                    "
                  />
                  <div class="products-card-desc">
                    <h5 v-if="settings.showPrices">
                      {{ product.price }} {{ t("website").products.sp }}
                    </h5>
                    <div
                      :inner-html.prop="product.description | truncate(50)"
                    ></div>
                  </div>
                  <span class="product-logo-title"
                    >ALFAISAL <span class="line-border"></span
                  ></span>
                </div>
              </router-link>
            </div>
          </b-col>
          <b-col cols="12" class="d-flex justify-content-center">
            <b-pagination
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              :value="DataFirstPageProductWeb"
              :total-rows="
                Math.ceil(filterdProducts.length / DataPageLengthProductWeb)
              "
              :per-page="DataPageLengthProductWeb"
              @input="(value) => updateDataPaginationForProducts(value)"
            >
              <template #prev-text>
                <unicon width="20" name="angle-right" fill="#A41F02" />
              </template>
              <template #next-text>
                <unicon width="20" name="angle-left" fill="#A41F02" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </section>
</template>
<script>
  import { useUtils as useI18nUtils } from "@core/libs/i18n";
  import { mapGetters, mapActions, mapState } from "vuex";
  import { handleLangChange } from "@/libs/event-bus";
  import vSelect from "vue-select";
  export default {
    components: {
      vSelect,
    },
    data: () => ({
      sortType: null,
    }),
    setup() {
      const { t } = useI18nUtils();
      return { t };
    },
    metaInfo() {
      return {
        titleTemplate: "Al Faisal - " + this.$t("title.products"),
        htmlAttrs: {
          lang: this.$i18n.locale,
        },
        meta: [
          {
            name: "description",
            content: this.productsInfo.description,
          },
          {
            name: "keywords",
            content: "keywords, keywords, keywords, ...",
          },
        ],
      };
    },
    computed: {
      ...mapState({
        settings: (state) => state.setting.settings,
      }),
      ...mapGetters([
        "productsInfo",
        "DataFirstPageProductWeb",
        "DataPageLengthProductWeb",
        "activeProductWeb",
        "filterdProducts",
        "filterDto",
        "websiteMainCategoriesList",
        "websiteSubCategoriesList",
      ]),
    },
    created() {
      this.getProductsForWebsite();
      handleLangChange(this.getProductsForWebsite);
    },
    methods: {
      ...mapActions([
        "getProductsForWebsite",
        "filterProductAction",
        "updateDataPaginationForProducts",
      ]),
      sortProducts() {
        this.activeProductWeb.sort((f, l) => {
          if (this.sortType == 0) {
            const F = f.name.toUpperCase();
            const L = l.name.toUpperCase();
            if (F > L) {
              return 1;
            } else {
              return -1;
            }
          } else if (this.sortType == 1) {
            const F = f.name.toUpperCase();
            const L = l.name.toUpperCase();
            if (F < L) {
              return 1;
            } else {
              return -1;
            }
          } else if (this.sortType == 2) {
            if (f.price > l.price) {
              return 1;
            } else {
              return -1;
            }
          } else if (this.sortType == 3) {
            if (f.price < l.price) {
              return 1;
            } else {
              return -1;
            }
          }
          return 0;
        });
      },
    },
    beforeDestroy() {
      this.$store.dispatch("app/setLoadingWebsite", true);
    },
  };
</script>
<style lang="scss">
  @import "src/assets/scss/variables/variables.scss";
  .products-main {
    .products-main-card-container {
      display: flex;
      .products-main-container-title {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 12px;
        color: $primary;
        .title-border {
          flex-grow: 1;
          background: $primary;
        }
        span {
          padding: 2px 0;
        }
      }
    }
    .products-main-container {
      background: #efefef;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.7);
      position: relative;
      padding: 2rem 3rem;
      padding-top: 80px;
      .products-title {
        pointer-events: none;
        transition: all 0.4s linear;
        position: absolute;
        left: -14px;
        top: 0;
        width: calc(100% + 14px);
        color: #fff;
        background: $primary;
        padding: 12px 1rem;
        padding-right: calc(1rem + 15px);
        text-align: center;
        line-height: 2;
        &:after {
          content: "";
          position: absolute;
          bottom: -15px;
          width: 14px;
          height: 15px;
          left: 0;
          background: #621200;
          clip-path: polygon(0 0, 100% 0, 0 100%);
        }
      }
      p {
        line-height: 1.7;
        padding-bottom: 1rem;
      }
      .sub-card-items {
        margin: auto;
        margin-bottom: 1rem;
        width: 300px;
        height: 220px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        &:after,
        &:before {
          content: "";
          width: 3px;
          height: 120px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background: $primary;
        }
        &:after {
          left: -3px;
        }
        &:before {
          right: -3px;
        }
      }
    }
    .products-cards-container {
      position: relative;
      .products-card-parent {
        margin-bottom: 2rem;
        .products-card {
          padding: 10px 0;
          width: 300px;
          margin: auto;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            left: 0;
            height: 3px;
            background: $secondaryPrimary;
            top: 0;
            width: 100%;
          }
          .products-card-title {
            transition: all 0.4s linear;
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
            color: #fff;
          }
          .products-card-image-desc-container {
            position: relative;
            &:after {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              bottom: 16px;
              width: 100%;
              background: rgba(0, 0, 0, 0.5);
              z-index: 1;
            }
            .products-card-image {
              height: 340px;
              width: 100%;
              z-index: 0;
            }
            .products-card-desc {
              pointer-events: none;
              transition: all 0.4s linear;
              position: absolute;
              left: -100%;
              bottom: 40px;
              z-index: -1;
              width: calc(100% + 14px);
              color: #fff;
              background: $secondaryPrimary;
              padding: 30px 1rem;
              padding-right: calc(1rem + 15px);
              text-align: center;
              line-height: 2;
              opacity: 0;
              visibility: hidden;
              &:after {
                content: "";
                position: absolute;
                bottom: -15px;
                width: 15px;
                height: 15px;
                right: 0;
                background: #a97216;
                clip-path: polygon(0 0, 100% 0, 100% 100%);
              }
            }
          }
          .product-logo-title {
            display: flex;
            align-items: center;
            letter-spacing: 12px;
            font-size: 12px;
            color: $primary;
            .line-border {
              margin-left: 12px;
              flex-grow: 1;
              height: 2px;
              background: $secondaryPrimary;
            }
          }
          &:hover {
            .products-card-title {
              top: 20%;
            }
            .products-card-image-desc-container {
              .products-card-desc {
                left: 0;
                z-index: 2;
                opacity: 1;
                visibility: visible;
              }
            }
          }
        }
      }
    }
  }
  [dir="ltr"] {
    .products-main {
      .products-main-container {
        .products-title {
          &:after {
            clip-path: polygon(0 0, 100% 0, 100% 100%);
          }
        }
      }
      .products-cards-container {
        .products-card-parent {
          .products-card {
            .products-card-image-desc-container {
              .products-card-desc {
                &:after {
                  clip-path: polygon(0 0, 100% 0, 0 100%);
                }
              }
            }
          }
        }
      }
    }
  }
  .vs__selected-options {
    input::placeholder {
      color: #fff;
    }
  }
  .vs__selected {
    color: #fff !important;
  }
</style>
